import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay } from 'swiper';

// Components
import Layout from '../../components/layout/Layout';
import RealisationCard from '../../components/card/Realisation';
import TestimonialCard from '../../components/card/Testimonial';
import ContactSection from '../../components/section/Contact';

// Service
const ServicePage = ({ pageContext, data }) => {

  const location = useLocation();
  const origin = location.origin;

  const pageImage = {
    url: data.service.frontmatter.image.cover ? data.service.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    secureUrl: data.service.frontmatter.image.cover ? data.service.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    type: data.service.frontmatter.image.cover ? data.service.frontmatter.image.cover.type : null,
    width: data.service.frontmatter.image.cover ? data.service.frontmatter.image.cover.childImageSharp.gatsbyImageData.width : null,
    height: data.service.frontmatter.image.cover ? data.service.frontmatter.image.cover.childImageSharp.gatsbyImageData.height : null,
    alt: data.service.frontmatter.image.alt ? data.service.frontmatter.image.alt : null
  };

  const pageSchemas = [
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: "Services",
          item: origin + "/services/"
        },
        { 
          name: data.service.frontmatter.title
        },
      ]
    }
  ];

  const headerBackgroundImage = data.service.frontmatter.image.cover ? data.service.frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`${data.service.frontmatter.meta.title} | ${data.site.siteMetadata.title} - L'entreprise spécialiste du bâtiment à Roanne`}
      pageDescription={data.service.frontmatter.meta.description}
      pageKeywords={data.service.frontmatter.meta.keywords}
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                {data.service.frontmatter.title &&
                  <h2 className="title text-white">{data.service.frontmatter.title}</h2>
                }
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">
                      <Link to={`/services/`}>Services</Link>
                    </span>
                    {data.service.frontmatter.title &&
                      <span>
                        <span><i className="bi bi-chevron-right"></i></span>
                        <span className="trail-item trail-end active">{data.service.frontmatter.title}</span>
                      </span>
                    }
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section>
        <div className="container pb-60">
          <div className="section-content">
            <div className="row">
              <div className="col-lg-8 col-12 order-lg-first">
                <MDXProvider
                  components={{
                    h2: props => <div className="mt-20 mb-10"><h2 {...props}>{props.children}</h2></div>,
                    h3: props => <div className="mt-20 mb-10"><h3 {...props}>{props.children}</h3></div>,
                    h4: props => <div className="mt-20 mb-10"><h4 {...props}>{props.children}</h4></div>,
                    h5: props => <div className="mt-20 mb-10"><h5 {...props}>{props.children}</h5></div>,
                    ul: props => <div className="mb-30"><ul {...props}>{props.children}</ul></div>,
                    a: props => <a {...props} className="text-theme-colored1 font-weight-600" target="_blank" rel="noopener noreferrer">{props.children}</a>,
                    blockquote: props => <blockquote {...props} className="tm-sc tm-sc-blockquote blockquote-style1">{props.children}</blockquote>,
                  }}
                >
                  <MDXRenderer
                    localImages={data.service.frontmatter.images}
                  >
                    {data.service.body}
                  </MDXRenderer>
                </MDXProvider>
              </div>
              <div className="col-lg-4 col-12 order-first">
                {data.services.nodes.length ?
                  <div className="widget widget_categories">
                    <h4 className="widget-title widget-title-line-bottom line-bottom-theme-colored1">Services</h4>
                    <ul>
                      {data.services.nodes.map((service, index) => (
                        <li key={index} className="cat-item"><Link to={`/service/${service.slug}`}>{service.frontmatter.title}</Link></li>
                      ))}
                    </ul>
                  </div>
                : null}
                {data.realisations.nodes.length ?
                  <div className="split-nav-menu clearfix widget widget-blog-list clearfix">
                    <h4 className="widget-title widget-title-line-bottom line-bottom-theme-colored1">Réalisations</h4>
                    <div className="tm-widget tm-widget-blog-list ">
                      <div className="tm-sc tm-sc-projects projects-style8-current-theme">
                        <div className="row">
                          {data.realisations.nodes.map(realisation => (
                            <RealisationCard key={realisation.id} data={realisation} cardStyle="2" cardClassName="col-6 col-md-4 col-lg-6 col-xl-6 mb-25" />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      {data.testimonials && data.testimonials.nodes.length ?
        <section id="testimnonials" className="bg-silver-light">
          <div className="container">
            <div className="section-title">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                      <div className="title-wrapper">
                        <h2 className="title">Ce que <span className="text-theme-colored1">nos clients</span> disent de <span className="text-theme-colored2">{data.site.siteMetadata.title}</span></h2>
                        <div className="title-seperator-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <Swiper
                className="swiper"
                modules={[A11y, Autoplay]}
                autoplay={{ delay: 5000, disableOnInteraction: true }}
                scrollbar={{ draggable: true }}
                data-aos="fade-up" data-aos-delay="-50"
                breakpoints={{
                  "380": {
                    "slidesPerView": 1,
                    "spaceBetween": 0,
                  },
                  "580": {
                    "slidesPerView": 2,
                    "spaceBetween": 0,
                  },
                  "768": {
                    "slidesPerView": 2,
                    "spaceBetween": 0,
                  },
                  "1024": {
                    "slidesPerView": 2,
                    "spaceBetween": 30,
                  }
                }}
              >
                {data.testimonials.nodes.map((testimonial, index) => (
                  <SwiperSlide key={testimonial.id} className="swiper-slide">
                    <TestimonialCard key={testimonial.id} data={testimonial} cardStyle="1" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      : null}
      {data.realisations && data.realisations.nodes.length ?
        <section id="realisations">
          <div className="container pt-90">
            <div className="section-title">
              <div className="row justify-content-md-center">
                <div className="col-md-10">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                      <div className="title-wrapper">
                        <h2 className="title">Nos <span className="text-theme-colored1">réalisations</span></h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Construction ou rénovation, l'entreprise {data.site.siteMetadata.title} a réalisé de nombreux projets à Roanne et ses alentours pour des particuliers ou professionnels.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="tm-sc tm-sc-projects projects-style8-current-theme">
                <div className="row">
                  {data.realisations.nodes.map(realisation => (
                    <RealisationCard key={realisation.id} data={realisation} cardStyle="1" cardClassName="col-md-6 col-lg-6 col-xl-4 mb-30" />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      : null}
      <ContactSection site={data.site} sectionStyle="3" />
    </Layout>
  )
}

ServicePage.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    service: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }).isRequired
  }),
}

export const query = graphql`
  query ($id: String!, $slug: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    service: mdx(id: {eq: $id}, fileAbsolutePath: { regex: "/data/services/" }) {
      frontmatter {
        meta {
          title
          description
          keywords
        }
        title
        description
        image {
          cover {
            childImageSharp {
              gatsbyImageData
              fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          credit {
            text
            link
          }
        }
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        home
        active
        date(formatString: "D MMMM YYYY", locale: "fr")
      }
      body
    }
    services: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/services/" },
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      nodes {
        frontmatter {
          title
          description
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" }, 
        frontmatter: { pages: {services: { in: [$slug] } }, active: { eq: true } }
      },
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      nodes {
        frontmatter {
          name
          rating
          website
          link
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
        body
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" }, 
        frontmatter: { pages: {services: { in: [$slug] } }, active: { eq: true } }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 6
    ) {
      nodes {
        frontmatter {
          title
          description
          type
          location
          surface
          budget
          duration 
          image {
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
    }
  }
`

export default ServicePage